*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  -o-tab-size: 4;
     tab-size: 4;
}

html {
  line-height: 1.15;
  
  -webkit-text-size-adjust: 100%;
  
}

body {
  margin: 0;
}

body {
  font-family:
		system-ui,
		-apple-system, 
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

b {
  font-weight: bolder;
}

code,
pre {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace;
  
  font-size: 1em;
  
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

table {
  text-indent: 0;
  
  border-color: inherit;
  
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
select {
  
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

summary {
  display: list-item;
}

dd,
h1,
h2,
h3,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  line-height: 1.5;
  
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: currentColor;
  
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img,
svg,
canvas,
iframe,
object {
  display: block;
  
  vertical-align: middle;
  
}

img {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.container {
  width: 100%;
}

@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 720px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.top-0 {
  top: 0px !important;
}

.-top-4 {
  top: -1rem !important;
}

.right-0 {
  right: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.left-3 {
  left: 0.75rem !important;
}

.left-5 {
  left: 1.25rem !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-50 {
  z-index: 50 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
          order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
          order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
          order: 3 !important;
}

.float-left {
  float: left !important;
}

.m-0 {
  margin: 0px !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-60 {
  margin-top: 15rem !important;
}

.mt-px {
  margin-top: 1px !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -0.75rem !important;
}

.-mt-4 {
  margin-top: -1rem !important;
}

.-mt-5 {
  margin-top: -1.25rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mr-32 {
  margin-right: 8rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mr-px {
  margin-right: 1px !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mr-3 {
  margin-right: -0.75rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: -webkit-box !important;
  display: flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.contents {
  display: contents !important;
}

.hidden {
  display: none !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-20 {
  height: 5rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-auto {
  height: auto !important;
}

.h-px {
  height: 1px !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100dvh !important;
}

.max-h-full {
  max-height: 100% !important;
}

.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-28 {
  width: 7rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-52 {
  width: 13rem !important;
}

.w-96 {
  width: 24rem !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-2\/12 {
  width: 16.666667% !important;
}

.w-3\/12 {
  width: 25% !important;
}

.w-4\/12 {
  width: 33.333333% !important;
}

.w-5\/12 {
  width: 41.666667% !important;
}

.w-8\/12 {
  width: 66.666667% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.flex-1 {
  -webkit-box-flex: 1 !important;
          flex: 1 1 0% !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-grow {
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.border-separate {
  border-collapse: separate !important;
}

.transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

.resize {
  resize: both !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.flex-col-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.content-center {
  align-content: center !important;
}

.items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.items-center {
  -webkit-box-align: center !important;
          align-items: center !important;
}

.items-stretch {
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
}

.justify-start {
  -webkit-box-pack: start !important;
          justify-content: flex-start !important;
}

.justify-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.justify-center {
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.justify-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.justify-items-center {
  justify-items: center !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.self-center {
  align-self: center !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.border-0 {
  border-width: 0px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border {
  border-width: 1px !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-l {
  border-left-width: 1px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-none {
  border-style: none !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-main-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(240, 130, 78, var(--tw-border-opacity)) !important;
}

.border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.border-gray-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.border-red-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.border-blue-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.border-blue-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.hover\:border-main-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(240, 130, 78, var(--tw-border-opacity)) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-accent {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(51, 55, 69, var(--tw-bg-opacity)) !important;
}

.bg-overlay {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-overlay-dark {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.bg-closed {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(204, 0, 0, var(--tw-bg-opacity)) !important;
}

.bg-limited {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 153, 0, var(--tw-bg-opacity)) !important;
}

.bg-available {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(102, 153, 0, var(--tw-bg-opacity)) !important;
}

.bg-main-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(242, 149, 105, var(--tw-bg-opacity)) !important;
}

.bg-main-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(240, 130, 78, var(--tw-bg-opacity)) !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.bg-red-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.bg-red-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.bg-yellow-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.bg-yellow-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.bg-green-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.bg-blue-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.bg-blue-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.bg-blue-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.hover\:bg-main-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(240, 130, 78, var(--tw-bg-opacity)) !important;
}

.hover\:bg-main-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 111, 61, var(--tw-bg-opacity)) !important;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.bg-black-from-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0))) !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) !important;
}

.bg-black-from-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5))) !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)) !important;
}

.bg-gr-main {
  background-image: linear-gradient(349deg, rgba(240,130,78,1), rgba(240,94,78,1)) !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-center {
  background-position: center !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-scale-down {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.align-middle {
  vertical-align: middle !important;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

.italic {
  font-style: italic !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.text-main-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(242, 149, 105, var(--tw-text-opacity)) !important;
}

.text-main-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(240, 130, 78, var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.text-red-300 {
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.text-red-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.text-yellow-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.text-yellow-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.text-green-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.text-green-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.text-green-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.text-blue-300 {
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.text-blue-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.text-blue-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.hover\:text-main-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(236, 111, 61, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-main {
  --tw-shadow: 0px 20px 50px 0px rgba(1, 1, 64, 0.08) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty, ) !important;
  --tw-brightness: var(--tw-empty, ) !important;
  --tw-contrast: var(--tw-empty, ) !important;
  --tw-grayscale: var(--tw-empty, ) !important;
  --tw-hue-rotate: var(--tw-empty, ) !important;
  --tw-invert: var(--tw-empty, ) !important;
  --tw-saturate: var(--tw-empty, ) !important;
  --tw-sepia: var(--tw-empty, ) !important;
  --tw-drop-shadow: var(--tw-empty, ) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
          transition-duration: 150ms !important;
}

.ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

@media (min-width: 540px) {

  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:mt-0 {
    margin-top: 0px !important;
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .sm\:h-4 {
    height: 1rem !important;
  }

  .sm\:w-60 {
    width: 15rem !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:flex-1 {
    -webkit-box-flex: 1 !important;
            flex: 1 1 0% !important;
  }

  .sm\:flex-grow {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }

  .sm\:flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .sm\:items-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }

  .sm\:p-0 {
    padding: 0px !important;
  }

  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .sm\:pl-3 {
    padding-left: 0.75rem !important;
  }
}

@media (min-width: 720px) {

  .md\:visible {
    visibility: visible !important;
  }

  .md\:invisible {
    visibility: hidden !important;
  }

  .md\:mt-0 {
    margin-top: 0px !important;
  }

  .md\:mb-0 {
    margin-bottom: 0px !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:h-16 {
    height: 4rem !important;
  }

  .md\:w-60 {
    width: 15rem !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-2\/3 {
    width: 66.666667% !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-2\/12 {
    width: 16.666667% !important;
  }

  .md\:w-10\/12 {
    width: 83.333333% !important;
  }

  .md\:flex-initial {
    -webkit-box-flex: 0 !important;
            flex: 0 1 auto !important;
  }

  .md\:flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .md\:border-2 {
    border-width: 2px !important;
  }

  .md\:p-8 {
    padding: 2rem !important;
  }

  .md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .md\:text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}

@media (min-width: 960px) {

  .lg\:visible {
    visibility: visible !important;
  }

  .lg\:invisible {
    visibility: hidden !important;
  }

  .lg\:mt-0 {
    margin-top: 0px !important;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1rem !important;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0px !important;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:flex {
    display: -webkit-box !important;
    display: flex !important;
  }

  .lg\:hidden {
    display: none !important;
  }

  .lg\:w-48 {
    width: 12rem !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lg\:flex-1 {
    -webkit-box-flex: 1 !important;
            flex: 1 1 0% !important;
  }

  .lg\:flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .lg\:justify-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }

  .lg\:border-r {
    border-right-width: 1px !important;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0px !important;
  }

  .lg\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .lg\:pt-0 {
    padding-top: 0px !important;
  }

  .lg\:pr-0 {
    padding-right: 0px !important;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1rem !important;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lg\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1rem !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
}

.toggle-btn input {
  display: none;
}

.toggle-btn--red > div {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.toggle-btn--red input:checked + div {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.toggle-btn--green > div {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.toggle-btn--green input:checked + div {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}