*
    margin: 0
    padding: 0
    border: 0
    box-sizing: border-box

@import 'styles/variables.scss'

@import '../node_modules/ngx-toastr/toastr.css'
@import '../node_modules/flag-icons/css/flag-icons.min.css'
@import '../node_modules/angular-calendar/css/angular-calendar.css'
@import '../node_modules/quill/dist/quill.core.css'
@import '../node_modules/quill/dist/quill.snow.css'

@import 'styles/ng-default.scss'
@import 'styles/theme.scss'
@import 'styles/global'

@import 'styles/dashboard'
@import 'styles/alerts'
@import 'styles/calendar'
@import 'styles/animations'
@import 'styles/inplace'

\:root
    font-size: 12px !important

    .mdc-notched-outline__notch
        border-right: none

    .mat-mdc-form-field input
        margin-top:6px

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix
        padding-top: 8px
        padding-bottom: 8px
        min-height: 42px
        width: auto

    button.mat-mdc-icon-button.mat-mdc-button-base
        width: 30px
        height: 30px  
        padding: 4px      

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label
        top: 18px

    .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above
        top: 30px

    .mat-mdc-icon-button svg, .mat-mdc-icon-button img
        width: 12px
        height: 12px

    mat-checkbox.text-red-500, mat-checkbox.text-red-500 label
        color: #c00 !important

    mat-checkbox.text-red-500 .mdc-checkbox .mdc-checkbox__background
        border-color: #c00 !important


    .mat-mdc-option,
    mat-optgroup .mat-mdc-optgroup-label
        min-height:20px

    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab
        flex-grow: 0

    .mat-mdc-tab-labels,
    app-collection-table table
        border-bottom:1px solid #ddd !important

    app-phone-input
        .mdc-text-field,            
        .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
        .mat-mdc-form-field-infix
            padding:4px
            min-height:42px

        .mat-mdc-select-arrow-wrapper
            display: none !important
            
    .phoneInput
        min-width:300px

    .mat-header-cell,
    .mat-mdc-header-row
        font-size: 11px
        font-weight: 400


    .mdc-data-table__cell:first-of-type, .mdc-data-table__header-cell:first-of-type
        padding-left: 16px
    .mdc-data-table__cell, .mdc-data-table__header-cell
        padding: 0px 2px
    
    mat-dialog-container .mat-mdc-radio-button .mdc-radio
        padding: 0px



@import '../node_modules/@ng-select/ng-select/themes/default.theme.css' //scss doesnt seem to exist ?

.mat-suffix-fix
    top: -0.25rem

@-moz-document url-prefix()
    .mat-suffix-fix
        bottom: -0.25rem
        top: auto

.pulse
    animation: pulse-animation 2s infinite

.select-material
    //@import '~@ng-select/ng-select/scss/material.theme.scss'

    ng-select.ng-select-opened .ng-placeholder
            top: 1.5rem !important

    ng-select
        margin-bottom: 1.2rem !important
        &.ng-invalid.ng-touched .ng-select-container
            border-color:#ff5722
            border-width:2px
        &.ng-invalid.ng-touched .ng-placeholder
            color:#ff5722

        .ng-input
            padding: 0 0.8rem !important
            min-height:2rem
    
        .ng-select-container
            min-height: 42px !important

            &::after
                z-index: 0 !important

        .ng-value-label, .ng-placeholder
            margin-top: -0.2rem

        .ng-placeholder
            position: absolute !important
            top: 1rem !important


        .ng-value-container
            border-top: 0.2rem solid transparent !important

        .ng-clear-wrapper, .ng-arrow-wrapper, .ng-placeholder, .ng-input
            z-index: 2 !important

    .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder
        transform: translateY(-1.6em) scale(0.75) perspective(100px) translateZ(0.001px) !important

.select-default
    //@import '~@ng-select/ng-select/scss/default.theme.scss'

    .ng-option-marked
        background-color: #f5faff !important

    .ng-option-selected
        background-color: #ebf5ff !important
    
ng-dropdown-panel
    margin-top: 0px !important

.admins-select
    .ng-select-container
        background-color: transparent !important
        border: 2px solid #fff !important

    .ng-arrow
        border-color: #fff transparent transparent !important

    .ng-select-opened > .ng-select-container .ng-arrow
        border-color: transparent transparent #fff !important

    .ng-value-label, .ng-input > input, .ng-placeholder
        color: #fff !important

.select-tags
    .ng-value
        padding: 0 !important

body > ng-dropdown-panel
    margin-top: -20px

.checkbox-wrap .mat-checkbox-label
    white-space: normal

.tooltip
    font-size: 0.75rem !important

.flag-icon-background
    background-size: cover

.not-found-height
    height: calc(100vh - 128px - 4rem)

.mat-form-field-prefix
    margin-right: 0.5rem !important

button.mat-mdc-unelevated-button:not(:disabled)
    color:#fff

.button-group-small
    .mat-button-toggle-label-content
        line-height: 1 !important
        font-size: 0.875rem !important
        // color: #707070 !important
        padding: 0.5rem 1rem !important

.custom-container
    mat-dialog-container
        padding: 0 !important
        resize: both !important
        position: relative !important
        width: 90dvw
        height: 90dvh

    .mat-dialog-content
        margin: 0 !important
        padding: 0 !important
        max-height: none !important

    .mdc-dialog .mdc-dialog__content
        padding:0px !important

    .mat-mdc-dialog-content
        max-height: none !important


    &__small
        mat-dialog-container
            width: 60dvw
            height: 60dvh
    
    &--no-resize
        mat-dialog-container
            resize: none !important

    .mat-dialog-content
        height: 100%
        max-height: none

.z-override
    z-index: 2000 !important

.mat-paginator-navigation-previous, .mat-paginator-navigation-next
    display: flex !important
    align-items: center !important
    justify-content: center !important

.mat-form-field-subscript-wrapper
    margin-top: 0.3rem !important

.ql-editor.ql-blank::before
    font-style: normal

quill-editor
    width:100%
    display:block

.mat-icon-button
    .mat-badge-content
        right: -3px !important
        top: -3px !important

.mat-mdc-icon-button
  --mdc-icon-button-icon-size: 15px !important

.show-on-hover
    &-child
        visibility: hidden

    &-parent:hover &-child
        visibility: visible

.display-on-hover
    &-child
        display: none

    &-parent:hover &-child
        display: block

    &-parent:hover &-hidden
        display: none

.cdk-drop-list-dragging
    .show-on-hover-child
        visibility: hidden !important

.e-inplaceeditor
    .e-editable-overlay-icon
        padding-left: 0.25rem !important

    .e-editable-value-wrapper
        padding: 0 !important

        &:hover
            background: none !important

.cdk-drag-placeholder
    opacity: 0 !important

.cdk-drag-preview
    box-sizing: border-box !important
    background: #fff !important

tr.cdk-drag-preview
    display: flex !important
    align-items: center
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)

    td
        flex-grow: 1 !important
        display: block !important
        box-shadow: none !important
        border: none !important

.cdk-drop-list-dragging *:not(.cdk-drag-placeholder)
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.app-edit-no-icon
    .e-editable-value
        margin: 0 !important
    
    .e-editable-overlay-icon
        display: none !important

    .e-editable-component
        min-width: auto !important

.sub_value .e-editable-inline
    width: 150px

.sub_desc .e-editable-inline
    width: 50px

.no-border-right
    .mat-form-field-outline-end
        border-right-style: none !important
        border-radius: 0 !important

.no-border-left
    .mat-form-field-outline-start
        border-left-style: none !important
        border-radius: 0 !important

.no-border
    .mat-form-field-outline-end, .mat-form-field-outline-start
        display: none !important
        
.fix-height
    .mat-form-field-flex
        height: 48px !important

    .mat-select-value, .mat-select-value-text
        overflow: visible !important

.ng-select-red
    .ng-select-container
        background: #ffaeae !important

.ng-select-yellow
    .ng-select-container
        background: #f8f9c3 !important

.ng-select-blue
    .ng-select-container
        background: #b0e0e6 !important

.ng-placeholder-gray
    .ng-placeholder
        background: #fafafa !important

.auto-infinix
    .mat-form-field-infix
        width: auto !important

.phone-country-select
    .mat-select-arrow-wrapper
        display: none !important

    .mat-form-field-outline
        background: #f9f9f9 !important

.gridster-row, .gridster-column
    border-color: #e1e1e1 !important

.mat-form-field-infix
    width: 100% !important

.mat-tab-label
    min-width: 120px !important

.select-bg-full
    .mat-select-arrow-wrapper
        display: none !important

    .mat-select-trigger
        position: static

    .select-bg
        position: absolute
        border-radius: 5px
        height: 30px
        width: 30px
        top: 0rem
        right: -0.7rem

.multi-lang
    .mat-form-field-infix
        margin-right: 41px !important

.booking-tooltip
    max-height: 60vh
    background-color: #ffffff !important
    color: #4a5568 !important
    overflow: hidden

    &.tooltip-right:after
        border-color: transparent #fff transparent transparent !important

    &.tooltip-top:after
        border-color: #fff transparent transparent transparent !important
    
.checkbox-wrap
    .mat-checkbox-layout
        white-space: normal

.timepicker__header
    background-color: $main-color !important

.timepicker-button
    color: $main-color !important

.clock-face__clock-hand
    background-color: $main-color !important

    &:after
        background-color: $main-color !important

.clock-face__number > span.active
    background-color: $main-color !important

.timepicker-dial__control_editable:focus
    color: $main-color !important
    outline: $main-color !important

.clock-face__clock-hand_minute::before
    border-color: $main-color !important

app-collection-table .mat-mdc-outlined-button.mat-mdc-button-base
    height:20px
    margin: 3px 0px

app-collection-table .mat-mdc-outlined-button .mat-mdc-button-touch-target
    display: none

app-collection-table .mat-column-actions
    pointer-events: none

app-collection-table .mat-column-actions button,
app-collection-table .mat-column-actions a
    pointer-events: auto

.mat-tab-body-wrapper
    flex: 1 1 0% !important

mat-tab-body .mat-mdc-tab-body-content
    overflow: hidden

.select-material .ng-select.ng-select-disabled .ng-select-container::after
    background-image: none

gridster.mobile gridster-item
    height: 400px !important

.scheduler-cals
    .cal-days
        border-top: none !important
        border-bottom: none !important

ng-dropdown-panel[ng-reflect-append-to="body"]
    margin-top: -1.1rem !important

.mat-select-white
    .mat-select-value-text, .mat-select-arrow
        color: #fff !important

.last-lable-left
    .mat-tab-label[aria-label="center-information"]
        margin-left: auto

.gjs-mdl-dialog
    width: 100% !important

.gjs-btn-prim
    font-size: 15px !important

.loader, .loader:after
    border-radius: 50%
    width: 10em
    height: 10em

.loader
    margin: 60px auto
    font-size: 10px
    position: relative
    text-indent: -9999em
    border-top: 1.1em solid rgba(255,255,255, 0.2)
    border-right: 1.1em solid rgba(255,255,255, 0.2)
    border-bottom: 1.1em solid rgba(255,255,255, 0.2)
    border-left: 1.1em solid #ffffff
    transform: translateZ(0)
    animation: load8 1.1s infinite linear

.gjs-am-assets .gjs-am-close
    display: none !important

.timepicker-dial__hint-container--hidden, .timepicker-dial__period--hidden
    display: none !important

ngx-material-timepicker-container > div
    z-index: 1000 !important

.overlay-fix mat-dialog-container
    height: auto

@import '../node_modules/swiper/swiper-bundle.css'

.swiper-button-disabled
    display: none !important

swiper-container
    overflow: hidden !important

.mat-mdc-tab.mdc-tab.mat-mdc-tab-disabled span.mdc-tab__content
    pointer-events: all



app-button
    opacity: 1 !important
    -webkit-appearance: none !important
    -moz-appearance: none !important
    appearance: none !important

@keyframes load8
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

@keyframes pulse-animation
    0%
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2)
    100%
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)
        

@media only screen and (max-width: 960px)
    .cdk-overlay-pane.custom-container
        mat-dialog-container
            resize: none !important
            border-radius: 0 !important
            width: 100vw !important
            height: 100vh !important

@media only screen and (max-width: 540px)
    .cdk-overlay-pane.custom-container
        max-width: 100vw !important
        width: 100% !important

        mat-dialog-container
            border-radius: 0 !important

.hoverMinH120:hover 
    min-height: 120px