mwl-calendar-month-view:not(.default)
    .cal-month-view
        background: transparent !important

        .cal-days
            border: none

            .cal-cell-row
                border: none

        .available
            .month-day, .session
                background-color: #669900

        .closed
            .month-day, .session
                background-color: #cc0000
                opacity: 0.6

        .limited
            .month-day, .session
                background-color: #ff9900

        .cal-day-cell
            min-height: auto

            &:not(:last-child)
                border-right: none

            &.cal-today
                background: none

        .cal-cell-row
            &:hover
                background: none

            .cal-cell
                &:hover
                    background: none

mwl-calendar-month-view
    .cal-month-view
        background: transparent !important

        .cal-day-cell
            cursor: pointer

        .cal-day-cell
            min-height: auto

            &.cal-today
                background: none

        .cal-cell-row
            &:hover
                background: none

            .cal-cell
                &:hover
                    background: none