.dashboard
    height: 100dvh
    width: 100%

.mat-drawer-container
    background: transparent !important

mat-drawer
    width: 180px !important

.drawer
    background: lighten($main-color, 2%) !important

    &__link
        a, mat-icon
            color: $sidenav-link !important

    .active, .active:focus
        background-color: darken(#282c36, 2%)
        position: relative

        a, mat-icon
            color: #ffffff !important

        &:before
            content: ''
            display: block
            position: absolute
            height: 100%
            width: 2px
            left: 0
            top: 0
            bottom: 0
            background-color: $main-color

        &:hover
            background-color: darken(#282c36, 2%)

    .active-sub, .active-sub:focus
        background: transparentize(#ffffff, 0.95)

        a, mat-icon
            color: #ffffff !important

        &:hover
            background: transparentize(#ffffff, 0.95)

    &__expandable-5
        margin-top: 2rem

.sidenav-grid
    display: grid
    grid-template-columns: 1
    grid-template-rows: 70px 1fr

.list
    &__root
        padding: 0 !important

    &__inner
        &.expanded
            background-color: darken(#282c36, 2%)

        .mat-list-item-content
            padding-left: 2rem !important