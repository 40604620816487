.inplace
    &__value
        position: relative
        cursor: pointer

        &--underlined::after
            content: ''
            display: block
            position: absolute
            width: 100%
            left: 0
            right: 0
            top: 100%
            border-bottom: 1px dashed #4a5568

        ul
            list-style-type: disc !important
            list-style-position: inside !important
            padding-left: 20px

    &__pencil
        display: none !important

    &__value-container
        &:hover > .inplace__pencil
            display: inline-block !important

    &__input
        border-bottom: 1px solid #4a5568

    .ng-value-container
        padding-right: 10px !important

    
